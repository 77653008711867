import React from 'react';

import Icon from './Icon';

export default function AppleIcon({
  text = 'Apple',
  className = '',
}: { text?: string; className?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.8428 7.14383C16.7386 7.22464 14.8998 8.2608 14.8998 10.5648C14.8998 13.2297 17.2397 14.1725 17.3097 14.1958C17.2989 14.2533 16.938 15.487 16.076 16.744C15.3074 17.8502 14.5047 18.9546 13.2836 18.9546C12.0625 18.9546 11.7482 18.2453 10.3385 18.2453C8.96474 18.2453 8.47629 18.978 7.35932 18.978C6.24235 18.978 5.46298 17.9544 4.56689 16.6973C3.52893 15.2212 2.69031 12.928 2.69031 10.7515C2.69031 7.26055 4.96017 5.40911 7.19411 5.40911C8.38112 5.40911 9.37059 6.18848 10.1158 6.18848C10.8252 6.18848 11.9314 5.36242 13.2818 5.36242C13.7936 5.36242 15.6325 5.40911 16.8428 7.14383ZM12.6407 3.8845C13.1992 3.22186 13.5942 2.30242 13.5942 1.38298C13.5942 1.25548 13.5835 1.12619 13.5601 1.02203C12.6515 1.05615 11.5704 1.62721 10.9185 2.38323C10.4067 2.96506 9.92907 3.8845 9.92907 4.8165C9.92907 4.95657 9.95242 5.09664 9.96319 5.14154C10.0207 5.15231 10.114 5.16488 10.2074 5.16488C11.0227 5.16488 12.0481 4.61897 12.6407 3.8845Z" />
      </svg>
    </Icon>
  );
}
