import React from 'react';

import Icon from './Icon';

export default function AndroidIcon({
  text = 'Android',
  className = '',
}: { text?: string; className?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.49879 6.83048C3.18865 6.83048 2.9255 6.93863 2.7092 7.15494C2.49289 7.37124 2.3847 7.63081 2.3847 7.93375V12.5852C2.3847 12.8957 2.49286 13.1586 2.7092 13.3749C2.9255 13.5913 3.18865 13.6994 3.49879 13.6994C3.8087 13.6994 4.07024 13.5913 4.2832 13.3749C4.49573 13.1586 4.60229 12.8957 4.60229 12.5852V7.93375C4.60229 7.63077 4.49413 7.37124 4.2778 7.15494C4.06146 6.93863 3.80173 6.83048 3.49879 6.83048Z" />
        <path d="M12.4556 2.65491L13.2237 1.23807C13.2741 1.14427 13.2562 1.07234 13.1697 1.02177C13.0759 0.978154 13.0038 1.00025 12.9534 1.0865L12.1746 2.51466C11.4893 2.21168 10.7646 2.05992 10.0003 2.05992C9.2358 2.05992 8.51094 2.21171 7.82593 2.51466L7.04711 1.0865C6.9965 1.00025 6.92441 0.978349 6.83081 1.02177C6.74417 1.07257 6.72623 1.14427 6.77684 1.23807L7.54492 2.65491C6.7661 3.05208 6.14582 3.60521 5.68431 4.31551C5.22279 5.02621 4.99194 5.80284 4.99194 6.64678H14.9977C14.9977 5.80304 14.7668 5.02637 14.3053 4.31551C13.8438 3.60521 13.2271 3.05208 12.4556 2.65491ZM8.01516 4.71571C7.9321 4.79897 7.83289 4.8404 7.71758 4.8404C7.60204 4.8404 7.50485 4.79897 7.42557 4.71571C7.34629 4.63285 7.30664 4.53403 7.30664 4.41833C7.30664 4.30302 7.34629 4.20401 7.42557 4.12095C7.50485 4.03808 7.60227 3.99665 7.71758 3.99665C7.83289 3.99665 7.9321 4.03808 8.01516 4.12095C8.09802 4.2042 8.13965 4.30302 8.13965 4.41833C8.13942 4.53384 8.09783 4.63285 8.01516 4.71571ZM12.5745 4.71571C12.4951 4.79897 12.3977 4.8404 12.2825 4.8404C12.167 4.8404 12.0678 4.79897 11.9849 4.71571C11.9019 4.63285 11.8604 4.53403 11.8604 4.41833C11.8604 4.30302 11.9019 4.20401 11.9849 4.12095C12.0678 4.03808 12.167 3.99665 12.2825 3.99665C12.3979 3.99665 12.495 4.03808 12.5745 4.12095C12.6539 4.2042 12.6934 4.30302 12.6934 4.41833C12.6934 4.53384 12.6538 4.63285 12.5745 4.71571Z" />
        <path d="M5.0349 14.2402C5.0349 14.5722 5.15022 14.8533 5.38088 15.084C5.61173 15.3146 5.89281 15.43 6.22462 15.43H7.02515L7.03611 17.8857C7.03611 18.1959 7.14427 18.4592 7.36057 18.6755C7.57688 18.8919 7.83664 19 8.13939 19C8.44933 19 8.71264 18.8919 8.92898 18.6755C9.14532 18.4592 9.25347 18.1959 9.25347 17.8857V15.4302H10.7462V17.8857C10.7462 18.1959 10.8543 18.4592 11.0707 18.6755C11.287 18.8919 11.5501 19 11.8602 19C12.1702 19 12.4335 18.8919 12.6498 18.6755C12.8662 18.4592 12.9743 18.1959 12.9743 17.8857V15.4302H13.7856C14.1101 15.4302 14.3875 15.3148 14.6186 15.0842C14.8493 14.8535 14.9646 14.5724 14.9646 14.2404V7.03582H5.0349V14.2402Z" />
        <path d="M16.5013 6.83048C16.1983 6.83048 15.9388 6.93704 15.7225 7.14957C15.5062 7.36249 15.398 7.62404 15.398 7.93375V12.5852C15.398 12.8957 15.5061 13.1586 15.7225 13.3749C15.9388 13.5913 16.1985 13.6994 16.5013 13.6994C16.8112 13.6994 17.0746 13.5913 17.2909 13.3749C17.5072 13.1586 17.6154 12.8957 17.6154 12.5852V7.93375C17.6154 7.624 17.5072 7.36249 17.2909 7.14957C17.0746 6.93704 16.8112 6.83048 16.5013 6.83048Z" />
      </svg>
    </Icon>
  );
}
